"use client";

import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";

import Image from "next/image";
import SlidesContainer from "./SlidesContainer";

import styles from "./Carousel.module.scss";
import Autoplay from "embla-carousel-autoplay";
import { SlideShowItem } from "@/lib/strapiApiTypes/homepage";

type Props = {
  slidesData: SlideShowItem[];
};

const EmblaCarousel = ({ slidesData }: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    Autoplay({ playOnInit: true, delay: 3000 }),
  ]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollNext = useCallback((): void => {
    if (emblaApi) {
      emblaApi.scrollNext();
      emblaApi.plugins()?.autoplay.stop;
    }
  }, [emblaApi]);

  const scrollPrev = useCallback((): void => {
    if (emblaApi) {
      emblaApi.scrollPrev();
      emblaApi.plugins()?.autoplay.stop;
    }
  }, [emblaApi]);

  const scrollTo = useCallback(
    (index: number) => {
      if (emblaApi) {
        emblaApi.scrollTo(index);
        emblaApi.plugins()?.autoplay.stop;
      }
    },
    [emblaApi]
  );

  useEffect(() => {
    if (!emblaApi) return;
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", () => {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    });
  }, [emblaApi]);

  return (
    <div className={styles.carouselWrapper}>
      <div className={styles.embla}>
        <div className={styles.emblaViewport} ref={emblaRef}>
          <SlidesContainer slidesData={slidesData} />
        </div>
        <button className={styles.emblaPrev} onClick={scrollPrev}>
          <span className={styles.arrowImgWrapper}>
            <Image
              src="/images/arrowLeft.svg"
              alt="arrowLeft"
              fill
              className={styles.emblaImage}
            />
          </span>
        </button>
        <button className={styles.emblaNext} onClick={scrollNext}>
          <span className={styles.arrowImgWrapper}>
            <Image
              src="/images/arrowRight.svg"
              alt="arrowRight"
              fill
              className={styles.emblaImage}
            />
          </span>
        </button>
        <div className={styles.emblaDots}>
          {scrollSnaps.map((_, index) => (
            <button
              key={index}
              aria-label={`Buton pentru slide-ul ${index + 1}`}
              onClick={() => scrollTo(index)}
              className={styles.emblaDotButton}
            >
              <div
                className={`${styles.emblaDot} ${
                  index === selectedIndex ? styles.isSelected : ""
                }`}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmblaCarousel;
