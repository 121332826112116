import React from "react";
import Link from "next/link";
import ButtonBase from "../ButtonBase/ButtonBase";

type Props = {
  ordinary?: boolean;
  href: string;
  leftSideImage?: React.ReactNode;
  text: string;
};

const ButtonLink = ({ href, ...buttonProps }: Props) => {
  return (
    <Link href={href}>
      <ButtonBase {...buttonProps} />
    </Link>
  );
};

export default ButtonLink;
