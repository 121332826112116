type Props = { className: string };

const Book = ({ className }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="fi-rr-book-alt" clipPath="url(#clip0_10122_303)">
        <path
          id="Vector"
          d="M18.5 1.80251C18.0315 1.41078 17.4826 1.127 16.8921 0.971311C16.3016 0.815624 15.684 0.791848 15.0833 0.901674L11.8983 1.48001C11.163 1.61504 10.4944 1.99336 10 2.55417C9.5043 1.99239 8.8338 1.61399 8.09667 1.48001L4.91667 0.901674C4.31597 0.791752 3.69844 0.815216 3.10781 0.970404C2.51718 1.12559 1.96789 1.40871 1.4988 1.79972C1.02972 2.19073 0.652322 2.68007 0.393318 3.2331C0.134315 3.78613 4.0677e-05 4.38933 0 5.00001L0 13.9942C4.78799e-05 14.9698 0.342481 15.9146 0.967611 16.6636C1.59274 17.4127 2.46092 17.9187 3.42083 18.0933L8.65917 19.0458C9.5458 19.2069 10.4542 19.2069 11.3408 19.0458L16.5833 18.0933C17.5425 17.9178 18.4097 17.4115 19.034 16.6625C19.6583 15.9135 20.0001 14.9692 20 13.9942V5.00001C20.0004 4.38954 19.8662 3.78651 19.6069 3.23383C19.3476 2.68116 18.9697 2.19245 18.5 1.80251ZM9.16667 17.44C9.09667 17.43 9.02667 17.4183 8.95667 17.4058L3.71917 16.4542C3.14314 16.3494 2.62216 16.0457 2.24707 15.5962C1.87198 15.1466 1.66658 14.5797 1.66667 13.9942V5.00001C1.66667 4.33697 1.93006 3.70108 2.3989 3.23224C2.86774 2.7634 3.50363 2.50001 4.16667 2.50001C4.31758 2.50037 4.46816 2.51403 4.61667 2.54084L7.8 3.12417C8.18301 3.19426 8.52939 3.39623 8.77904 3.69504C9.02868 3.99385 9.16582 4.37064 9.16667 4.76001V17.44ZM18.3333 13.9942C18.3334 14.5797 18.128 15.1466 17.7529 15.5962C17.3778 16.0457 16.8569 16.3494 16.2808 16.4542L11.0433 17.4058C10.9733 17.4183 10.9033 17.43 10.8333 17.44V4.76001C10.8333 4.36968 10.9702 3.99172 11.2203 3.69201C11.4703 3.39231 11.8176 3.18988 12.2017 3.12001L15.3858 2.53667C15.7464 2.47107 16.117 2.48555 16.4714 2.5791C16.8258 2.67265 17.1553 2.84297 17.4365 3.07801C17.7178 3.31305 17.9439 3.60705 18.0988 3.93919C18.2538 4.27133 18.3339 4.63349 18.3333 5.00001V13.9942Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10122_303">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Book;
