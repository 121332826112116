"use client";
import React, { useState } from "react";
import styles from "./MedicalImagingAccordion.module.scss";
import Book from "@/public/images/imgComponents/book";
import ButtonLink from "../Buttons/ButtonLink/ButtonLink";
import clsx from "clsx";

const tabsData = [
  {
    title: "RMN",
    slug: "/rmn",
    content:
      "Rezonanță magnetică (RMN) este o modalitate imagistică indispensabilă pentru diagnosticul și managementul unei game largi de patologii, precum cele oncologice, neurologice, musculoscheletale și ale părților moi etc., deoarece permite caracterizarea în detaliu a leziunilor descrise, contribuind astfel la stabilirea unui diagnostic corect, pe baza căruia medicul curant recomandă un tratament adecvat.",
  },
  {
    title: "CT",
    slug: "ct",
    content:
      "Tomografia computerizată (CT) este o metodă non invazivă de diagnosticare a unor boli ce altfel nu ar putea fi diagnosticate decât cu ajutorul unei intervenții chirurgicale sau a unei autopsii.",
  },
  {
    title: "Mamografie",
    slug: "mamografie",
    content:
      "Mamografia mamară este o investigație cu valoare complementară în depistarea precoce a cancerului mamar.",
  },
  {
    title: "DXA",
    slug: "dxa",
    content:
      "Osteodensitometria (DXA) este investigația paraclinică de măsurare a densității osoase prin care se poate pune diagnosticul de osteoporoză. Scorurile obținute permit diagnosticarea osteopeniei sau osteoporozei, cu abordări terapeutice diferite. Osteodensitometria se recomandă tuturor femeilor după instalarea menopauzei, iar frecvența controlui ulterior o stabilește medicul specialist. Și bărbații pot suferi de osteoporoză, în special fumătorii și cei cu boli cronice ce primesc tratament cortizonic, iar indicația de osteodensitometrie o stabilește medicul curant.",
  },
  {
    title: "Endoscopie",
    slug: "radiologie-imagistica",
    content:
      "Endoscopia digestivă superioară reprezintă metodă prin care se examinează esofagul, stomacul și duodenul. Examinarea se realizează cu ajutorul unui endoscop,un tub flexibil, de calibru redus, care se introduce în cavitatea bucală și de aici în esofag, stomac, duoden, fără fără a produce un disconfort respirator major.\nPentru că examinarea să se poată efectua în condiții normale, este necesar că stomacul să fie gol (fără conținut). Pentru această, este necesar să nu mâncați și să nu beți cu 7 ore înainte de manevră (cu o seară înainte de manevră). Dacă manevră se face după ora 14:00 se poate mânca până în ora 07:00 un mic dejun redus cantitativ, fără grăsimi și carne.\nDe asemenea, se pot preleva biopsii din leziunile evidențiate, pentru examinarea acestora la microscop. Pentru procedura sunt necesare aproximativ 60 de minute, manevră propriu-zisă având durata de 15 - 20 minute.",
  },
  {
    title: "Colonoscopie",
    slug: "colonoscopie",
    content:
      "Colonoscopia (endoscopia digestivă inferioară) & rectosigmoidoscopia este examinarea intestinului gros,utilizând un aparat flexibil și subțire care preia imagini din interiorul intestinului și le redă pe un monitor. Cu ajutorul acestui aparat se pot vizualiza anomaliile sau leziunile prezente la nivelul colonului (intestin gros), obțînându-se informații in plus față de utilizarea tehnicii radiologice (irigografie).\nDe asemenea, se pot preleva biopsii din leziunile evidențiate, pentru examinarea acestora la microscop. Pentru procedura sunt necesare aproximativ 60 de minute, manevră propriu-zisă având durata de 15 - 20 minute.",
  },

  {
    title: "Bronhoscopie",
    slug: "bronhoscopie",
    content:
      "Bronhoscopia este o procedura minim invazivă prin care este posibilă vizualizarea interiorului cailor aeriene: laringe, corzile vocale, trahee, bronsii principale și bronsii segmentare. Bronhoscopia se efecteaza atât în scop diagnostic cât și în scop terapeutic." +
      "\nInainte de a efectua investigația se recomandă câteva analize de rutină: spirometrie,analize de sânge și electrocardiogramă." +
      "\nBronhoscopia durează 5 - 10 minute, în funcție de complexitatea cazului, a cooperării pacientului și a manevrelor efectuate." +
      "\nInainte de a efectua procedura, pacientul nu mănâncă și nu bea cu minim 6 ore înainte. De asemenea, după investigație, pacientul este sfătuit să nu mănânce și să nu bea timp de aproximativ 2 ore." +
      "\nDacă pacientul are diabet zaharat insulino-dependent, se va discuta cu medicul momentul optim pentru procedura. După bronhoscopie pacientul poate merge acasă în aceeași zi. Este supravegheat aproximativ 1 - 2 ore după procedura (clinic, saturație, tensiune arterială). Dacă au fost folosite substanțe sedative înainte de procedura, este indicat că pacientul să nu conducă în aceași zi." +
      "\nDupa bronhoscopie pacientul poate merge acasă în aceeași zi. Este supravegheat aproximativ 1 - 2 ore după procedura (clinic, saturație, tensiune arterială). Dacă au fost folosite substanțe sedative înainte de procedura, este indicat că pacientul să nu conducă în aceeași zi.",
  },
];

const MedicalImagingAccordion = () => {
  const [activeTab, setActiveTab] = useState(tabsData[0]?.slug || null);

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };
  const leftSideImage = <Book className={styles.bookSvg} />;

  return (
    <div className={styles.wrapper}>
      <h3>Imagistică Medicală</h3>
      <div className={styles.accordion}>
        <div className={styles.tabs}>
          {tabsData.map((tab) => (
            <button
              key={tab.slug}
              className={clsx(
                styles.tabButton,
                activeTab === tab.slug && styles.active
              )}
              onClick={() => handleTabClick(tab.slug)}
            >
              <span>{tab.title}</span>
            </button>
          ))}
        </div>
        <div className={styles.content}>
          {tabsData.map((tab) => (
            <div
              key={tab.slug}
              className={clsx(
                styles.tabContent,
                activeTab === tab.slug && styles.active
              )}
            >
              <p>{tab.content}</p>
              <div className={styles.btnWrapper}>
                <ButtonLink
                  href={`/specialitati-medicale/radiologie-imagistica`}
                  ordinary={true}
                  leftSideImage={leftSideImage}
                  text={"Citește mai mult"}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MedicalImagingAccordion;
