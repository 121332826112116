import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/Carousel/EmblaCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/GetInTouch/GetInTouch.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/LatestNews/News/News.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/LatestNews/LatestNews.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/Buttons/ButtonBase/ButtonBase.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/ImageryCenter/ImageryCenter.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/MedicalImagingAccordion/MedicalImagingAccordion.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/MedicalStaff/Card/MedicalStaffCard.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/MedicalStaff/Badges/BadgeBase.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/MedicalStaff/Section/MedicalStaffSection.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Home/MedicalSpeciality/Cards/ImageCard/MedicalSpecialityImageCard.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/Grids/ResponsiveGrid/ResponsiveGrid.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Home/PopularServices/PopularServices.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Home/MedicalSpeciality/Cards/IconCard/MedicalSpecialityIconCard.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Home/MedicalSpeciality/Sections/IconSection/MedicalSpecialtyIconSection.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/AboutUs/AboutUs.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/Scores/Scores.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Home/BalneoMedCenter/BalneoMedCenter.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Home/Perfomances/Perfomances.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Home/Perfomances/Performance/Perfomance.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Grid2/Grid2.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
