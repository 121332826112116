import React from "react";
import Image from "next/image";

import Book from "@/public/images/imgComponents/book";
import ButtonLink from "../Buttons/ButtonLink/ButtonLink";

import styles from "./Carousel.module.scss";
import { SlideShowItem } from "@/lib/strapiApiTypes/homepage";

type Props = {
  slidesData: SlideShowItem[];
};

const SlidesContainer = ({ slidesData }: Props) => {
  const leftSideImage = <Book className={styles.bookSvg} />;

  return (
    <>
      <div className={styles.emblaContainer}>
        {slidesData.map((slideData, index) => (
          <div className={styles.emblaSlide} key={slideData.title}>
            <div className={styles.overlay} />
            <Image
              alt={slideData.image.alternativeText}
              src={slideData.image.formats.large.url}
              placeholder="blur"
              blurDataURL="https://spital-sofianu.s3.eu-central-1.amazonaws.com/logo-10x10.png"
              fill
              quality={100}
              priority={index === 0}
              style={{
                objectFit: "cover",
                zIndex: 0,
              }}
            />
            <div className={styles.emblaText}>
              <div className={styles.content}>
                <h3>{slideData.title}</h3>
                <p>{slideData.subtitle}</p>
              </div>
              <ButtonLink
                text={"Vezi mai multe"}
                href={slideData.linkUrl}
                leftSideImage={leftSideImage}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default SlidesContainer;
